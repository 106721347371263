import * as dateConfig from './date-config';

export const getFinalProducts = (fetchedProduct, planId, filterByPlanId, deviceRrp = 0) => {
    const isPlanIdAvailable = planId ? true : false;
    return fetchedProduct.map(productItem => {
        if (filterByPlanId && planId) {  //filter by plan Id for SBC
            productItem['plans'] = productItem.plans.find(planItem => planItem.bandNo === planId);
        }
        // else {   //filter by device rrp for SEPCO
        //     productItem['plans'] = productItem.plans.find(planItem => planItem.devicePriceStartingRange < deviceRrp && planItem.devicePriceEndingRange >= deviceRrp);
        // }

        if (productItem['plans']) {
            productItem['billingDate'] = dateConfig.getBillingOrCoverageDate('billing');
            productItem['coverageDate'] = dateConfig.getBillingOrCoverageDate('coverage');
            productItem['coverageDesc'] = productItem.coverageDesc;
            productItem.plans['discountedProductPrice'] = isPlanIdAvailable && productItem.plans.retailPriceValueWithTax;
            productItem.plans['actualProductPrice'] = isPlanIdAvailable && productItem.plans.retailPriceValueWithTax;
            productItem.plans['fulfillmentSystemPrice'] = isPlanIdAvailable && productItem.plans.retailPriceValueWithTax;
            productItem.plans['paymentSystemPrice'] = isPlanIdAvailable && productItem.plans.retailPriceValueWithTax;
            productItem.plans['repairServiceFee'] = isPlanIdAvailable && productItem.plans.repairServiceFee;
            productItem.plans['replacementServiceFee'] = isPlanIdAvailable && productItem.plans.replacementServiceFee;
            productItem.plans['deviceEndingRange'] = isPlanIdAvailable && productItem.plans.devicePriceEndingRange;
            productItem.plans['merchantId'] = productItem.plans.merchantId;

            productItem['isSubscription'] = true;
            productItem['isPopular'] = productItem.productName === 'DREI_AT_SMART_COVER_PLUS' ? true : false;
            productItem['protectionValue'] = '';
            productItem['isPhoneReplacement'] = true;
            productItem['currencySymbol'] = '€';
            productItem['tenureToShow'] = 'pro Monat';
            productItem['featureList'] = getFeatureList(productItem.productId);  // feature list on landing page
            productItem['coverageObj'] = getCoverageObj(productItem.productId);  // coverage tab on product page
            productItem['productDescription'] = 'PRODUCT_DESCRIPTION.' + productItem.productId  //
            productItem['productName'] = 'PRODUCT_NAME.' + productItem.productId
        }
        else {     //if device rrp doesn't match with any plan
            localStorage.setItem('isRouteFromPurchase', true);
        }
        return productItem;
    })
}

const getFeatureList = (productId) => {
    const featureList = [];
    switch (productId) {
        case 'ATDREIAUSADLDEWTHEFT12':
            featureList.push('FEATURE.FREE_PROTECTION_FIRST_MONTHS','FEATURE.EXTEND_PROTECTION', 'FEATURE.CHOOSE_BETWEEN', 'FEATURE.REPLACEMENT')
            return featureList;
        case 'ATDREIAUSADLDEW12':
            featureList.push('FEATURE.FREE_PROTECTION_FIRST_MONTHS','FEATURE.EXTEND_PROTECTION', 'FEATURE.CHOOSE_BETWEEN')
            return featureList;
        case 'ATDREIAUSADLDEWTHEFT1201':
            featureList.push('FEATURE.FREE_PROTECTION_FIRST_2_MONTHS','FEATURE.EXTEND_PROTECTION', 'FEATURE.CHOOSE_BETWEEN', 'FEATURE.REPLACEMENT')
            return featureList;
        case 'ATDREIAUSADLDEW1201':
            featureList.push('FEATURE.FREE_PROTECTION_FIRST_2_MONTHS','FEATURE.EXTEND_PROTECTION', 'FEATURE.CHOOSE_BETWEEN');
            return featureList;
        case 'ATDREIAUSADLDEW101':
            featureList.push('FEATURE.DISCOUNTED_PREMIUM_FF','FEATURE.EXTEND_PROTECTION', 'FEATURE.CHOOSE_BETWEEN')
            return featureList;
        case 'ATDREIAUSADLDEWTHEFT101':
            featureList.push('FEATURE.DISCOUNTED_PREMIUM_FF','FEATURE.EXTEND_PROTECTION', 'FEATURE.THEFT_PROTECTION_FF', 'FEATURE.CHOOSE_BETWEEN');
            return featureList;
        case 'ATDREIAUSADLDEWTHEFT24':
                featureList.push('FEATURE.ACS_FREE_PROTECTION_FIRST_2_MONTHS','FEATURE.ACS_EXTEND_PROTECTION', 'FEATURE.ACS_CHOOSE_BETWEEN', 'FEATURE.ACS_REPLACEMENT')
                return featureList;
        case 'ATDREIAUSADLDEW24':
                featureList.push('FEATURE.ACS_FREE_PROTECTION_FIRST_2_MONTHS','FEATURE.ACS_EXTEND_PROTECTION', 'FEATURE.ACS_CHOOSE_BETWEEN')
                return featureList;      
    }
}

const getCoverageObj = (productId) => {
    const coverageList = [];
    switch (productId) {
        case 'ATDREIAUSADLDEWTHEFT12':
            coverageList.push({
                HEADING: 'BREAKDOWN.HEADING',
                PARA_1: 'BREAKDOWN.WE_WILL_COVER',
                PARA_2: 'BREAKDOWN.PLEASE_SEE'
            }, {
                HEADING: 'ACCIDENTAL_DAMAGE.HEADING',
                PARA_1: 'ACCIDENTAL_DAMAGE.PARA_1',
                PARA_2: 'ACCIDENTAL_DAMAGE.PARA_2'
            }, {
                HEADING: 'REPLACE_REPAIR.HEADING',
                PARA_1: 'REPLACE_REPAIR.PARA_1',
                PARA_2: ''
            }, {
                HEADING: 'THEFT.HEADING',
                PARA_1: 'THEFT.PARA_1'
            })
            return coverageList;
        case 'ATDREIAUSADLDEW12':
            coverageList.push({
                HEADING: 'BREAKDOWN.HEADING',
                PARA_1: 'BREAKDOWN.WE_WILL_COVER',
                PARA_2: 'BREAKDOWN.PLEASE_SEE'
            }, {
                HEADING: 'ACCIDENTAL_DAMAGE.HEADING',
                PARA_1: 'ACCIDENTAL_DAMAGE.PARA_1',
                PARA_2: 'ACCIDENTAL_DAMAGE.PARA_2'
            }, {
                HEADING: 'REPLACE_REPAIR.HEADING',
                PARA_1: 'REPLACE_REPAIR.PARA_1',
                PARA_2: ''
            })
            return coverageList;
        case 'ATDREIAUSADLDEWTHEFT1201':
            coverageList.push({
                HEADING: 'BREAKDOWN.HEADING',
                PARA_1: 'BREAKDOWN.WE_WILL_COVER',
                PARA_2: 'BREAKDOWN.PLEASE_SEE'
            }, {
                HEADING: 'ACCIDENTAL_DAMAGE.HEADING',
                PARA_1: 'ACCIDENTAL_DAMAGE.PARA_1',
                PARA_2: 'ACCIDENTAL_DAMAGE.PARA_2'
            }, {
                HEADING: 'REPLACE_REPAIR.HEADING',
                PARA_1: 'REPLACE_REPAIR.PARA_1',
                PARA_2: ''
            }, {
                HEADING: 'THEFT.HEADING',
                PARA_1: 'THEFT.PARA_1'
            })
            return coverageList;
        case 'ATDREIAUSADLDEW1201':
            coverageList.push({
                HEADING: 'BREAKDOWN.HEADING',
                PARA_1: 'BREAKDOWN.WE_WILL_COVER',
                PARA_2: 'BREAKDOWN.PLEASE_SEE'
            }, {
                HEADING: 'ACCIDENTAL_DAMAGE.HEADING',
                PARA_1: 'ACCIDENTAL_DAMAGE.PARA_1',
                PARA_2: 'ACCIDENTAL_DAMAGE.PARA_2'
            }, {
                HEADING: 'REPLACE_REPAIR.HEADING',
                PARA_1: 'REPLACE_REPAIR.PARA_1',
                PARA_2: ''
            })
            return coverageList;
        case 'ATDREIAUSADLDEWTHEFT101':
            coverageList.push({
                HEADING: 'BREAKDOWN.HEADING',
                PARA_1: 'BREAKDOWN.WE_WILL_COVER',
                PARA_2: 'BREAKDOWN.PLEASE_SEE'
            }, {
                HEADING: 'ACCIDENTAL_DAMAGE.HEADING',
                PARA_1: 'ACCIDENTAL_DAMAGE.PARA_1',
                PARA_2: 'ACCIDENTAL_DAMAGE.PARA_2'
            }, {
                HEADING: 'REPLACE_REPAIR.HEADING',
                PARA_1: 'REPLACE_REPAIR.PARA_1',
                PARA_2: ''
            }, {
                HEADING: 'THEFT.HEADING',
                PARA_1: 'THEFT.PARA_1'
            })
            return coverageList;
        case 'ATDREIAUSADLDEW101':
            coverageList.push({
                HEADING: 'BREAKDOWN.HEADING',
                PARA_1: 'BREAKDOWN.WE_WILL_COVER',
                PARA_2: 'BREAKDOWN.PLEASE_SEE'
            }, {
                HEADING: 'ACCIDENTAL_DAMAGE.HEADING',
                PARA_1: 'ACCIDENTAL_DAMAGE.PARA_1',
                PARA_2: 'ACCIDENTAL_DAMAGE.PARA_2'
            }, {
                HEADING: 'REPLACE_REPAIR.HEADING',
                PARA_1: 'REPLACE_REPAIR.PARA_1',
                PARA_2: ''
            })
            return coverageList;
            case 'ATDREIAUSADLDEWTHEFT24':
                coverageList.push({
                    HEADING: 'ACS_BREAKDOWN.HEADING',
                    PARA_1: 'ACS_BREAKDOWN.WE_WILL_COVER',
                    PARA_2: 'ACS_BREAKDOWN.PLEASE_SEE'
                }, {
                    HEADING: 'ACS_ACCIDENTAL_DAMAGE.HEADING',
                    PARA_1: 'ACS_ACCIDENTAL_DAMAGE.PARA_1',
                    PARA_2: 'ACS_ACCIDENTAL_DAMAGE.PARA_2'
                }, {
                    HEADING: 'ACS_REPLACE_REPAIR.HEADING',
                    PARA_1: 'ACS_REPLACE_REPAIR.PARA_1',
                    PARA_2: ''
                }, {
                    HEADING: 'THEFT.HEADING',
                    PARA_1: 'THEFT.PARA_1'
                })
                return coverageList;
                case 'ATDREIAUSADLDEW24':
                coverageList.push({
                    HEADING: 'ACS_BREAKDOWN.HEADING',
                    PARA_1: 'ACS_BREAKDOWN.WE_WILL_COVER',
                    PARA_2: 'ACS_BREAKDOWN.PLEASE_SEE'
                }, {
                    HEADING: 'ACS_ACCIDENTAL_DAMAGE.HEADING',
                    PARA_1: 'ACS_ACCIDENTAL_DAMAGE.PARA_1',
                    PARA_2: 'ACS_ACCIDENTAL_DAMAGE.PARA_2'
                }, {
                    HEADING: 'ACS_REPLACE_REPAIR.HEADING',
                    PARA_1: 'ACS_REPLACE_REPAIR.PARA_1',
                    PARA_2: ''
                })
                return coverageList;        
        
    }
}

export const getBandList = (deviceRrps, productItem) => {
    let bandList = [], planIds = [];
    deviceRrps.forEach(deviceRrpItem => {
        productItem.plans.forEach(planItem => {
            if (planItem.devicePriceStartingRange < deviceRrpItem && planItem.devicePriceEndingRange >= deviceRrpItem && !planIds.includes(planItem.bandNo)) {
                bandList.push({
                    planId: planItem.bandNo,
                    devicePriceStartingRange: planItem.devicePriceStartingRange,
                    devicePriceEndingRange: planItem.devicePriceEndingRange,
                    bandDropDownKey: `${planItem.devicePriceStartingRange} - ${planItem.devicePriceEndingRange}`,
                    merchantId: planItem.merchantId
                })
                planIds.push(planItem.bandNo);  // to get unique band items
            }
        })
    });
    return bandList;
}

export const getModifiedPrice = (item) => {
    if (item) {
        const modifiedPrice = item.toFixed(2).toString().replace(".", ",")
        return modifiedPrice;
    }
}

export const numberWithCommas = (x) => {
    if (x) {
        let arr = x.toString().split('.');
        return arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
}