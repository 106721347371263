import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react';
import languageStore from '../../store/languageStore';
import topRightIcon from '../../assets/images/product-details-page-topbar-icon.svg'
import * as EN from '../../i18n/messages/en-US';
import * as DE from '../../i18n/messages/de-DE';
import { LOCALES } from '../../i18n';
import * as constants from '../../app.constant';
import planStore from '../../store/planStore';
import mobileCoverPlus from '../../assets/pdf/bolttech_Mobile_Cover_and_Mobile_Cover_Plus_2021_01_22.pdf';
import mobileCoverDisplay from '../../assets/pdf/bolttech_Mobile_Cover_Display_2021_01_22.pdf';
import mobileCoverAndMobileCoverPlus from '../../assets/pdf/BOLTTECH_Mobile-Cover_and_Mobile-Cover-Plus.pdf';
import mobileAcsCoverAndMobileCoverPlus from '../../assets/pdf/BOLTTECH_Mobile-Cover_and_Mobile-Cover-Plus_ACS.pdf';

import { getModifiedPrice, numberWithCommas } from '../../service/prod-config';
import appleIcon from "../../assets/images/appleIcon.png"

const ProductDetails = observer(() => {

    const [pdfUrl, setPdfUrl] = useState('');
    const [maxClaims, setmaxClaims] = useState(2)

    useEffect(() => {
        getPdfUrl();
    }, [])

    const getPdfUrl = () => {
        switch (planStore.productId) {
            case constants.PRODUCT_IDS.MOBILE_COVER_PLUS:
                setPdfUrl(mobileCoverAndMobileCoverPlus);
                break;
            case constants.PRODUCT_IDS.MOBILE_COVER_PLUS_12M:
                setPdfUrl(mobileCoverAndMobileCoverPlus);
                //setmaxClaims(1)
                break;
            case constants.PRODUCT_IDS.MOBILE_COVER:
                setPdfUrl(mobileCoverAndMobileCoverPlus);
                break;
            case constants.PRODUCT_IDS.MOBILE_COVER_12M:
                setPdfUrl(mobileCoverAndMobileCoverPlus);
                break;

            case constants.PRODUCT_IDS.DISCOUNTED_DREI_MOBILE_COVER:
                setPdfUrl(mobileCoverAndMobileCoverPlus);
                break;
            case constants.PRODUCT_IDS.DISCOUNTED_DREI_MOBILE_COVER_PLUS:
                setPdfUrl(mobileCoverAndMobileCoverPlus);
                break;
            case constants.PRODUCT_IDS.ACS_PLUS_MOBILE_COVER:
                    setPdfUrl(mobileAcsCoverAndMobileCoverPlus);
                    break;
            case constants.PRODUCT_IDS.ACS_MOBILE_COVER:
                    setPdfUrl(mobileAcsCoverAndMobileCoverPlus);
                    break;     
            default:
                break;
        }
    }

    const getText = (keyName) => {
        if (keyName) {
            const keyArray = keyName.split('.');
            if (languageStore.selectedLanguage === LOCALES.ENGLISH) {
                return EN[keyArray[0]][keyArray[1]];
            } else {
                return DE[keyArray[0]][keyArray[1]];
            }
        }
    };

    const slideToggle = (e) => {
        let el = e.target.parentElement;
        if (el.classList.contains('show')) {
            el.classList.remove('show');
            scrollTopEl(el);
        } else {
            removeShowClassFromAllList(el);
            el.classList.add('show');
            scrollTopEl(el);
        }
    };

    const scrollTopEl = (el) =>{
        const positionTop = el.getBoundingClientRect().top - 80;
        window.scrollTo({ top:window.scrollY + positionTop, behavior: "smooth" });
    }

    const removeShowClassFromAllList = (el) => {
        el.parentElement.querySelectorAll('li').forEach(element => {
            element.classList.remove('show');
        });
    }

    const goToDeviceInfoPage = () => {
        localStorage.setItem('isFromProductInfoPage', false);
        navigate(constants.ROUTES.DEVICE_INFO);
    }

    const navigateToDescription = () => {
        const el = document.getElementById('productDescription'),
            elList = document.getElementById('productDescriptionList');
        elList.querySelectorAll('li').forEach(element => {
            element.classList.remove('show');
        });
        el.classList.add('show');
        setTimeout(() => {
            scrollTopEl(el);
        }, 50);
    };

    return (
        <div className="product-details-page">
            <div className="top-graphics">
                <div className="container">
                    <img src={topRightIcon} alt="" />
                </div>
            </div>
            <div className="container product-details-wrapper">
                <div className="row">
                    <div className="col-md-5 col-lg-4">
                        <div className="product-card">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h2 className="productName" dangerouslySetInnerHTML={{ __html: getText(planStore.productName) }}></h2>
                            {planStore?.productName && getText(planStore.productName || "").includes('ACS') && (<img src={appleIcon} alt="Product" style={{ width: '100px', height: 'auto', marginLeft: '10px' }} />)}
                            </div>
                            <div className="productPrice">{getModifiedPrice(planStore.actualProductPrice)}{planStore.currencySymbol}</div>
                            <div className="per-month">{planStore.tenureToShow}</div>
                            <div className="button-wrapper"><button type="button" className="btn btn-cyan" onClick={goToDeviceInfoPage}><span>Fortfahren</span></button></div>
                            {planStore?.productName && getText(planStore.productName || "").includes('ACS') ? (
        <p>sehen “<span className="isThisProductBtn" onClick={navigateToDescription}>Ist dieses Produkt richtig für Sie</span>” finden Sie weitere Informationen zum Produkt, die Sie auch herunterladen können.</p>      
      ) : (
        <p>Unter “<span className="isThisProductBtn" onClick={navigateToDescription}>Ist dieses Produkt richtig für Sie</span>” finden Sie weitere Informationen zum Produkt, die Sie auch herunterladen können.</p>              
)}
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-8">
                        <div className="product-description">
                            <ul id="productDescriptionList">
                                <li id="coverageType" className="show">
                                {planStore.productId === constants.PRODUCT_IDS.ACS_MOBILE_COVER ? (
                                    <div className="feature-list" onClick={slideToggle}>Deckung - unlimitierte Schadenfälle bei Reparatur</div>)
                                    : planStore.productId === constants.PRODUCT_IDS.ACS_PLUS_MOBILE_COVER ? (
                                        <div className="feature-list" onClick={slideToggle}>    Deckung (Max. {maxClaims} Schadensfälle pro Jahr bei Diebstahl mit jeweils bis zu {numberWithCommas(planStore.deviceEndingRange)} {planStore.currencySymbol}  Leistung und unlimitierte Schadenfälle bei Reparatur)</div>)
                                    : (<div className="feature-list" onClick={slideToggle}>Deckung (Max. {maxClaims} Schadenfälle pro Jahr mit jeweils bis zu  {numberWithCommas(planStore.deviceEndingRange)}{planStore.currencySymbol} Leistung)</div>)}
                                    <div className="feature-list-description">
                                        <div className="row">
                                            {planStore.coverageObj && planStore.coverageObj.map(item => (
                                                <div className="col-sm-6" key={item.HEADING}>
                                                    <div className="feature-list-col">
                                                        <h2>{getText(item.HEADING)}</h2>
                                                        {item.PARA_1 && <p>{getText(item.PARA_1)}</p>}
                                                        {item.PARA_2 && <p>{getText(item.PARA_2)}</p>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </li>
                                <li id="productDescription" onClick={slideToggle}>
                                    <div className="feature-list">Ist dieses Produkt richtig für Sie?</div>
                                    <div className="feature-list-description">
                                        {planStore.productName && <h2 dangerouslySetInnerHTML={{ __html: getText(planStore.productName).replace("<br/>", " ") }}></h2>}
                                        <p>{getText(planStore.productDescription)}</p>
                                        <p><a href={pdfUrl} className="download-pdf" download>PDF herunterladen</a></p>
                                    </div>
                                </li>
                                <li id="whyBolttech" onClick={slideToggle}>
                                    <div className="feature-list">Warum bolttech</div>
                                    {planStore.productId === constants.PRODUCT_IDS.ACS_MOBILE_COVER ? (
                                              <div className="feature-list-description">
                                        <h2>Sofortiger Schutz |</h2>
                                        <p>Wir bieten ein umfassendes Angebot an Versicherungsleistungen für mobile Geräte. Sie können sicher sein, dass Ihr Gerät schnell repariert oder ersetzt wird, wenn nach Ablauf der Herstellergarantie ein Unfallschaden oder ein interner Defekt auftritt.</p>
                                    </div>)
                                    : planStore.productId === constants.PRODUCT_IDS.ACS_PLUS_MOBILE_COVER ? (
                                         <div className="feature-list-description">
                                        <h2>Sofortiger Schutz |</h2>
                                        <p>Wir bieten ein umfassendes Angebot an Versicherungsleistungen für mobile Geräte. Sie können sicher sein, dass Ihr Gerät schnell repariert oder ersetzt wird, wenn nach Ablauf der Herstellergarantie ein Unfallschaden oder ein interner Defekt auftritt. Wenn Ihr Gerät gestohlen wird, werden wir es ersetzen.</p>
                                    </div>)
                                    : ( <div className="feature-list-description">
                                        <h2>Sofortiger Schutz | Keine Mindestvertragslaufzeit</h2>
                                        <p>Wir bieten eine umfangreiche Auswahl an Versicherungsleistungen für mobile Geräte an. Sie können darauf vertrauen, dass Ihr Gerät rasch repariert oder ersetzt wird wenn es zu versehentlichen Beschädigungen oder auch internen Defekten nach Ablauf der Herstellergarantie kommen sollte. Wenn Ihr Gerät gestohlen wird, ersetzen wir es.</p>
                                    </div>)}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ProductDetails;